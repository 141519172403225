<template>
  <div
    class="position-relative d-flex flex-wrap col-12 fr-more__wrapper"
    style="min-height: 100vh"
  >
    <div v-for="(value, index) in textArray" :key="index" class="d-flex flex-wrap align-items-center col-12" :class="{'fr-type': whichQuestionType === index+1, 'mt-4': index !== 0}">
      <div class="position-relative col-4 px-0 text-center fr-more__image-wrapper">
        <div class="fr-animation w-100" :class="value.animationClass">
          <img :src="require(`@/assets/images/${value.image}`)"/>
        </div>
      </div>

      <div class="col-8 py-4 px-3 text-break fr-more__text">
        <h1
          class="fr-title__h3"
        >
          {{ value.title }}
        </h1>
        <p class="mb-0 text-break fr-title__span">{{ value.text }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-center w-100 mt-5">
      <button class="fr-button fr-button__more fr-button__animated" @click="goToFinish">
        To finish
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TellMeMoreExplained",
  data() {
    return {
      whichQuestionType: 1,
      anim: null,
      textArray: [
        {
          title: "Green hero...",
          text: `This is a green heart whose beat is heard far and wide. Its pulse echoes with the hope that we already have among our ranks responsible people who realize the importance of sustainable principles and responsible behaviour at all levels of our lives. And not just that you realize all this, but long ago you set off down the path of change for the good of the planet and the generations to follow. But, in doing so, you can never forget about yourself and your own well-being. Listen even more attentively and more often, as the world truly needs you. People need a role model and the inspiration to begin more earnestly following the green beat of change and a better future.`,
          animationClass: "fr-animation__l1",
          image: "leaf-1.png",
        },
        {
          title: "Green Knight...",
          text: `The green heart has been a core part of you for some time, now. Every now and again you forget about the environment and sustainable principles for everyday life, but that’s just because of the tempo of the modern era. But even that can be slowed down and controlled. It is exactly that calm and that control that will be one of the deciding factors for our common path towards a more sustainable and friendly future.`,
          animationClass: "fr-animation__l2",
          image: "leaf-2.png",
        },
        {
          title: "Green Guardian...",
          text: `The beat of the green heart has overtaken and enthralled you. You realise that the world cannot continue without this heartbeat, and that everything begins and ends with us humans. We need changes that lead to common efforts to bring about a brighter future. You are a person that can guard this knowledge and awareness and help it spread around the world.`,
          animationClass: "fr-animation__l3",
          image: "leaf-3.png",
        },
      ],
    };
  },
  created() {
    const typeObject = this.$store.state.typePercentage;
    if (
      typeObject.typeOne >= 2 ||
      (typeObject.typeOne === 1 &&
        typeObject.typeTwo === 1 &&
        typeObject.typeThree === 1)
    ) {
      this.whichQuestionType = 1;
    } else if (typeObject.typeTwo >= 2) {
      this.whichQuestionType = 2;
    } else if (typeObject.typeThree >= 2) {
      this.whichQuestionType = 3;
    }
    this.$store.commit("UPDATE_WHICH_QUESTION_TYPE", this.whichQuestionType);
  },
  methods: {
    goToFinish() {
      this.$emit("nextPage");
    },
  },
};
</script>
