import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

Vue.config.productionTip = false;

import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.prototype.$axios = axios;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
