<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <h1 class="text-center fr-title__h1 fr-more__wrapper-text">
      This is your green <br />
      heart
    </h1>
    <div class="fr-img__wrapper fr-more__wrapper-img">
      <img src="~@/assets/images/logo.png" />
    </div>
    <button class="fr-button fr-button__more" @click="$emit('nextPage', false)">
      Tell me more
    </button>
  </div>
</template>

<script>
export default {
  name: "TellMeMoreIntro",
};
</script>
