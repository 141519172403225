<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    style="min-height: calc(100vh - 100px)"
  >
    <div class="fr-img__wrapper mb-0">
      <img src="~@/assets/images/logo.png" />
    </div>
    <h1
      class="text-center fr-title__h1"
    >
      Measure your <br />
      green heart
    </h1>
    <button class="fr-button" @click="$emit('goToNextPage')">Start</button>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>
