<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center"
    style="min-height: 100vh"
  >
    <tell-me-more-intro
      v-if="showIntro"
      @nextPage="showIntro = $event"
    ></tell-me-more-intro>

    <tell-me-more-explained
      v-else-if="!showIntro"
      @nextPage="$emit('goToNextPage')"
    ></tell-me-more-explained>
  </div>
</template>

<script>
import TellMeMoreIntro from "../components/TellMeMoreIntro";
import TellMeMoreExplained from "../components/TellMeMoreExplained";
export default {
  name: "TellMeMore",
  components: { TellMeMoreExplained, TellMeMoreIntro },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showIntro: true,
    };
  },
};
</script>
