<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center col-10"
    style="height: 100vh"
  >
    <div class="fr-img__wrapper fr-qr mb-0">
      <img src="~@/assets/images/qr-code.png" />
    </div>

    <div
      class="
        position-relative
        d-flex
        flex-column
        align-items-center
        justify-content-center
        col-12
        fr-send__wrapper
      "
    >
      <span class="text-capitalize fr-title__label mb-4">Enter your email</span>
      <div
        class="
          d-flex
          flex-wrap
          justify-content-center
          col-md-9
          col-12
          px-0
          fr-form__wrapper
        "
      >
        <input
          v-model="email"
          class="col-9 fr-form"
          placeholder="name.surname@gmail.com"
        />
        <div class="col-3">
          <button class="fr-button fr-button__form" @click="sendEmail">
            Ok
          </button>
        </div>
      </div>
      <p
        v-if="showErrorText"
        class="mb-0 text-danger text-center fr-error-text fr-form__error"
      >
        Entered email is invalid.
      </p>
      <p
        v-if="showSuccessText"
        class="mb-0 text-success text-center fr-error-text fr-form__error"
      >
        Entered email is valid.
      </p>
    </div>

    <div class="position-relative col-md-9 col-12 px-0 checkbox-wrapper">
      <label
        class="container-checkbox"
        :class="{
          'text-danger': showRequiredText,
          'checkbox-text': agreeTypeResult,
        }"
        >By marking this check box I agree to receive the Green Heart Type
        result from the completed form on the email I have provided provided.
        <input type="checkbox" v-model="agreeTypeResult" />
        <span class="checkmark-checkbox"></span>
      </label>

      <label
        class="container-checkbox mt-3"
        :class="{
          'text-danger': showNewsErrorText,
          'checkbox-text': agreeNewsLetter,
        }"
        >By marking this checkbox I agree to receive the regular newsletter and
        other promotional information from the Green Heart campaign and it’s
        partners to the email I have provided. More about the privacy policy can
        be read <a href="https://drive.google.com/file/d/1VUA6--v-HwcxBZFc6-6SofMPyneXLj8p/view">here</a>.
        <input type="checkbox" v-model="agreeNewsLetter" />
        <span class="checkmark-checkbox"></span>
      </label>
    </div>

    <button
      class="fr-button fr-button__finish"
      :class="{ 'fr-button__disabled': !emailConfirmed }"
      :disabled="!emailConfirmed"
      @click="finishQuestionnaire"
    >
      Finish
    </button>
  </div>
</template>

<script>
export default {
  name: "SendEmail",
  data() {
    return {
      showErrorText: false,
      showSuccessText: false,
      agreeTypeResult: false,
      agreeNewsLetter: false,
      showNewsErrorText: false,
      showRequiredText: false,
      emailConfirmed: false,
      email: null,
    };
  },
  methods: {
    regexFilter(value) {
      return value.replace(/[`\\\]{}~!#$%^&*()|+=?;:'<>/]/gi, "");
    },
    async sendEmail() {
      try {
        const email = this.regexFilter(this.email);
        if (!this.agreeTypeResult && !this.agreeNewsLetter) {
          this.showRequiredText = true;
          this.showNewsErrorText = true;
        } else if (!this.agreeNewsLetter) {
          this.showNewsErrorText = true;
        } else if (!this.agreeTypeResult) {
          this.showRequiredText = true;
        } else if (
          email.includes("@") &&
          email.includes(".") &&
          !email.trim().includes(" ")
        ) {
          this.$axios.get(
            `${process.env.VUE_APP_MAIL_API_HOST}/email-gh?email=${email}&type=${this.$store.state.questionType}`
          );
          this.showErrorText = false;
          this.showSuccessText = true;
          this.emailConfirmed = true;
        } else {
          this.showErrorText = true;
          this.emailConfirmed = false;
        }
      } catch (error) {
        console.error(error);
        this.showErrorText = true;
      }
    },
    finishQuestionnaire() {
      if (this.emailConfirmed) {
        this.$router.go();
      }
    },
  },
};
</script>
