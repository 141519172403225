import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    questionType: 1,
    typePercentage: {
      typeOne: 0,
      typeTwo: 0,
      typeThree: 0,
    },
    quizAnswers: [
      {
        title: "How many times per year do you visit the great outdoors?",
        answers: [
          {
            id: 0,
            name: "Many times.",
            checked: false,
          },
          {
            id: 1,
            name: "Regularly.",
            checked: false,
          },
          {
            id: 2,
            name: "Nearly every day.",
            checked: false,
          },
        ],
      },
      {
        title: "I often use",
        answers: [
          {
            id: 0,
            name: "My own bottle for drinking water.",
            checked: false,
          },
          {
            id: 1,
            name: "A recycled shopping or tote bag.",
            checked: false,
          },
          {
            id: 2,
            name: "Public transportation.",
            checked: false,
          },
        ],
      },
      {
        title: "If at all possible",
        answers: [
          {
            id: 0,
            name: "I support manufacturers and brands that stand for principles of environmental and social responsibility.",
            checked: false,
          },
          {
            id: 1,
            name: "I support local producers and providers and buy products from ethical stores.",
            checked: false,
          },
          {
            id: 2,
            name: "I do not use items that are intended for reuse.",
            checked: false,
          },
        ],
      },
    ],
  },
  mutations: {
    SET_CHECKED_ANSWER(state, { questionId, answerId }) {
      state.quizAnswers[questionId].answers.map((element) => {
        element.checked = false;
      });
      state.quizAnswers[questionId].answers[answerId].checked = true;
    },
    UPDATE_WHICH_QUESTION_TYPE(state, value) {
      state.questionType = value;
    },
    SET_ANSWER_POINTS(state, answerId) {
      switch (answerId) {
        case 0:
          state.typePercentage.typeOne += 1;
          break;
        case 1:
          state.typePercentage.typeTwo += 1;
          break;
        default:
          state.typePercentage.typeThree += 1;
          break;
      }
    },
    RESET_ANSWER_POINTS(state) {
      state.typePercentage = {
        typeOne: 0,
        typeTwo: 0,
        typeThree: 0,
      };
    },
    RESET_ANSWERS(state) {
      Vue.set(state, "quizAnswers", [
        {
          title: "How many times per year do you visit the great outdoors?",
          answers: [
            {
              id: 0,
              name: "Many times.",
              checked: false,
            },
            {
              id: 1,
              name: "Regularly.",
              checked: false,
            },
            {
              id: 2,
              name: "Nearly every day.",
              checked: false,
            },
          ],
        },
        {
          title: "I often use",
          answers: [
            {
              id: 0,
              name: "My own bottle for drinking water.",
              checked: false,
            },
            {
              id: 1,
              name: "A recycled shopping or tote bag.",
              checked: false,
            },
            {
              id: 2,
              name: "Public transportation.",
              checked: false,
            },
          ],
        },
        {
          title: "If at all possible",
          answers: [
            {
              id: 0,
              name: "I support manufacturers and brands that stand for principles of environmental and social responsibility.",
              checked: false,
            },
            {
              id: 1,
              name: "I support local producers and providers and buy products from ethical stores.",
              checked: false,
            },
            {
              id: 2,
              name: "I do not use items that are intended for reuse.",
              checked: false,
            },
          ],
        },
      ]);
    },
  },
  actions: {},
});
