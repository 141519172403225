<template>
  <div
    class="
      position-relative
      d-flex
      flex-column
      align-items-center
      justify-content-center
      col-12
    "
    style="min-height: calc(100vh - 43px)"
  >
    <button
      class="position-absolute fr-arrow__back"
      @click="$emit('previousPage')"
    >
      <img src="~@/assets/images/arrow_back.png" />
    </button>
    <h1
      class="col-10 text-center fr-title__h2 fr-title__wrapper"
      style=""
    >
      {{ answerTitle }}
    </h1>
    <div
      v-for="(value, index) in answers"
      :key="index"
      class="d-flex flex-column align-items-center col-10 mb-3"
    >
      <label class="container fr-radio"
        >{{ value.name }}
        <input
          type="radio"
          :checked="value.checked"
          @change="answerChanged(value)"
          name="radio"
        />
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="position-relative d-flex flex-column align-items-center col-12">
      <button
        class="fr-button fr-button__next mb-5"
        style="margin-top: 60px"
        @click="goToNextAnswer"
      >
        Next
      </button>
      <p
        v-if="showErrorText"
        class="mb-0 text-danger text-center fr-error-text"
      >
        Choose one of the following answers
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Answers",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      answered: false,
      showErrorText: false,
      currentAnswer: 0,
    };
  },
  computed: {
    answerTitle() {
      return this.$store.state.quizAnswers[this.currentPage - 2].title;
    },
    answers() {
      return this.$store.state.quizAnswers[this.currentPage - 2].answers;
    },
  },
  methods: {
    goToNextAnswer() {
      if (this.answered) {
        this.showErrorText = false;
        this.answered = false;
        this.$store.commit("SET_ANSWER_POINTS", this.currentAnswer);
        this.$emit("goToNextPage");
        this.currentAnswer = 0;
      } else {
        this.showErrorText = true;
      }
    },
    answerChanged(value) {
      this.$store.commit("SET_CHECKED_ANSWER", {
        questionId: this.currentPage - 2,
        answerId: value.id,
      });
      (this.currentAnswer = value.id), (this.answered = true);
      this.showErrorText = false;
    },
  },
};
</script>
