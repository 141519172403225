<template>
  <div class="d-flex flex-wrap justify-content-center">
    <home-page v-if="currentPage === 1" @goToNextPage="nextPage"></home-page>

    <answers
      v-if="currentPage > 1 && currentPage < 5"
      :current-page="currentPage"
      @goToNextPage="nextPage"
      @previousPage="prevPage"
    ></answers>

    <tell-me-more
      v-if="currentPage === 5"
      :current-page="currentPage"
      @goToNextPage="nextPage"
    ></tell-me-more>

    <send-email
      v-if="currentPage === 6"
      :current-page="currentPage"
      @goToNextPage="nextPage"
    ></send-email>

    <div v-if="currentPage === 1" class="w-100 fr-powered">
      <p class="text-center mb-0 pb-md-4 pb-0 pt-md-0 pt-4">Powered by...</p>
    </div>

    <div
      v-if="currentPage > 1 && currentPage < 5"
      class="w-100 fr-powered"
      style="height: 43px"
    >
      <div class="progress h-100">
        <div
          class="progress-bar fr-progress"
          :class="progressBarWidth"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="25"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePage from "../components/HomePage";
import Answers from "./Answers";
import TellMeMore from "./TellMeMore";
import SendEmail from "./SendEmail";

export default {
  name: "Home",
  components: { SendEmail, TellMeMore, HomePage, Answers },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    progressBarWidth() {
      switch (this.currentPage - 1) {
        case 1:
          return "w-25";
        case 2:
          return "w-50";
        case 3:
          return "w-75";
        default:
          return "w-100";
      }
    },
  },
  methods: {
    nextPage() {
      this.currentPage += 1;
    },
    prevPage() {
      this.currentPage -= 1;
    },
  },
};
</script>
